// extracted by mini-css-extract-plugin
export var cyan = "banner-module--cyan--1r0TU";
export var cyanBg = "banner-module--cyan-bg--3g3XK";
export var cyanBo = "banner-module--cyan-bo--16y5M";
export var androidGreen = "banner-module--androidGreen--MHZQX";
export var androidGreenBg = "banner-module--androidGreen-bg--1IcY3";
export var androidGreenBo = "banner-module--androidGreen-bo--2oKHi";
export var lavender = "banner-module--lavender--2O3-l";
export var lavenderBg = "banner-module--lavender-bg--2Sx74";
export var lavenderBo = "banner-module--lavender-bo--IuowK";
export var corn = "banner-module--corn--1kToR";
export var cornBg = "banner-module--corn-bg--11ho9";
export var cornBo = "banner-module--corn-bo--3olJP";
export var trns = "banner-module--trns--3pkj-";
export var caption = "banner-module--caption--2Bb3y";
export var siteTitle = "banner-module--siteTitle--1txpT";