import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Carousel from 'react-bootstrap/Carousel'

import panorama from "../images/panorama.jpg"
import * as bannerStyles from "../styles/components/banner.module.sass"

const Banner = () => (
  <StaticQuery
    query={graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `}
    render={data => (
      <Carousel indicators={false} controls={false}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={panorama}
            alt="Devon Windmill Holidays"
          />
          <Carousel.Caption className={bannerStyles.caption}>
            <h1 className={bannerStyles.siteTitle}>{data.site.siteMetadata.title}</h1>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    )}
  />
)

export default Banner
