import React from "react"

import { Container, Row, Col } from "react-bootstrap"

import Banner from "./banner"
import Navbar from "./navBar"

const Layout = ({ children, pageInfo, showBanner }) => (
  <>
    <Container fluid className="px-0 main">
      <Navbar />
      {showBanner && <Banner />}
      <Row noGutters>
        <Col>
          <Container className="mt-2">
            <main>{children}</main>
          </Container>
        </Col>
      </Row>
    </Container>
    <Container fluid className="px-0">
      <Row noGutters>
        <Col className="footer-col">
          <footer>
            <span>
             Website by Alexandra Batey © {new Date().getFullYear()}
            </span>
          </footer>
        </Col>
      </Row>
    </Container>
  </>
)

Layout.defaultProps = { showBanner: false }

export default Layout
