import React from "react"

import { Navbar, Nav, Container } from "react-bootstrap"

const CustomNavbar = () => {
  return (
    <>
      <Navbar varient="light" expand="sm" id="site-navbar">
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="ml-auto navbar-dark" label="Menu" style={{ color: "white", border: "1px solid white" }}>Menu</Navbar.Toggle>
        <Navbar.Collapse>
          <Container fluid className="justify-content-center" style={{fontSize: "1.2rem"}}>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/gallery">Gallery</Nav.Link>
            <Nav.Link href="/accommodation">Accommodation</Nav.Link>
            <Nav.Link href="/pricing">Availability & Prices</Nav.Link>
            <Nav.Link href="/location">Location</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
          </Container>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default CustomNavbar
